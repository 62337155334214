<template>
  <base-widget :title="`Actives: ${devicesActives}`">
    <v-progress-circular
      :value="value"
      color="#39C2B2"
      rotate="270"
      size="128"
      width="24"
    />

    <div class="pa-3 headline font-weight-light">
      {{ value }}%
    </div>
  </base-widget>
</template>

<script>
  export default {
    name: 'WidgetDevicesActives',

    data: () => ({
      value: 50,
    }),
    computed: {
      totalDevices(){
        return this.$store.state.totalDevices
      },
      devicesActives(){
        this.value = ( (parseFloat(this.$store.state.devicesActives) / parseFloat(this.$store.state.totalDevices)) * 100 ).toFixed(0)
        return this.$store.state.devicesActives
      }
    }
  }
</script>
